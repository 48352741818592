.wysihtml5-editor blockquote {
    border-left: 4px solid #eee;
    margin-left: 0;
    padding-left: 30px;
}

.wysihtml5-editor a {
    color: #337ab7;
}

.wysihtml5-editor table td, .wysihtml5-editor table th {
  border: 1px dashed;
  min-width: 20px;
}

.wysihtml5-editor table td {
  border-color: LightGray;
}

.wysihtml5-editor table th {
  border-color: gray;
}

.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
iframe {
  border: 2px inset;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

.img-responsive {
  display: block;
  width: 100% \9;
  max-width: 100%;
  height: auto;
}
img {
  vertical-align: middle;
}
img {
  border: 0;
}

/* COLORS */
.wysiwyg-color-black {
  color: black;
}

.wysiwyg-color-silver {
  color: silver;
}

.wysiwyg-color-gray {
  color: gray;
}

.wysiwyg-color-white {
  color: white;
}

.wysiwyg-color-maroon {
  color: maroon;
}

.wysiwyg-color-red {
  color: red;
}

.wysiwyg-color-purple {
  color: purple;
}

.wysiwyg-color-fuchsia {
  color: fuchsia;
}

.wysiwyg-color-green {
  color: green;
}

.wysiwyg-color-lime {
  color: lime;
}

.wysiwyg-color-olive {
  color: olive;
}

.wysiwyg-color-yellow {
  color: yellow;
}

.wysiwyg-color-navy {
  color: navy;
}

.wysiwyg-color-blue {
  color: blue;
}

.wysiwyg-color-teal {
  color: teal;
}

.wysiwyg-color-aqua {
  color: aqua;
}

.wysiwyg-color-orange {
  color: orange;
}

/* STYLES*/
.wysiwyg-font-size-smaller {
  font-size: smaller;
}

.wysiwyg-font-size-larger {
  font-size: larger;
}

.wysiwyg-font-size-xx-large {
  font-size: xx-large;
}

.wysiwyg-font-size-x-large {
  font-size: x-large;
}

.wysiwyg-font-size-large {
  font-size: large;
}

.wysiwyg-font-size-medium {
  font-size: medium;
}

.wysiwyg-font-size-small {
  font-size: small;
}

.wysiwyg-font-size-x-small {
  font-size: x-small;
}

.wysiwyg-font-size-xx-small {
  font-size: xx-small;
}
.wysiwyg-text-align-right {
  text-align: right;
}

.wysiwyg-text-align-center {
  text-align: center;
}

.wysiwyg-text-align-left {
  text-align: left;
}

.wysiwyg-text-align-justify {
  text-align: justify;
}

.wysiwyg-float-left {
  float: left;
  margin: 0 8px 8px 0;
}

.wysiwyg-float-right {
  float: right;
  margin: 0 0 8px 8px;
}

.wysiwyg-clear-right {
  clear: right;
}

.wysiwyg-clear-left {
  clear: left;
}